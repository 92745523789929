import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { FlespiService, Device } from '../../Services/Flespi.service'
import TableHeader from '@/App/Components/TableHeader/Table-Header.vue'
import { TableHeaderBtn } from '@/App/Components/TableHeader/TableHeader'
import DeviceManagementDialog from './DeviceManagementDialog/Device-Management-Dialog.vue'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { VuetifyTableHeader } from '@/interfaces/vuetify'

const deviceManagementStore = namespace('DeviceManagement')
const sharedStore = namespace('Shared')

@Component({
  components: { TableHeader, DeviceManagementDialog }
})
export default class DeviceManagementPage extends Vue {
  $refs: {
    deviceManagementDialog: HTMLFormElement
  }

  @deviceManagementStore.Mutation
  private readonly setDevices: (devices: Device[]) => void

  @deviceManagementStore.Mutation
  private readonly destroyDevice: (id: number) => void

  @deviceManagementStore.State
  public readonly devices: Device[]

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public headerBtn: TableHeaderBtn[] = [
    { icon: 'fa fa-plus', tooltip: this.$t('deviceManagementPage.addNewDeviceTooltipText').toString(), action: 'createDevice' }
  ]
  public headers: VuetifyTableHeader[] = [
    { text: this.$t('deviceManagementPage.tableColNameText').toString(), align: 'left', sortable: true, value: 'name' },
    { text: this.$t('deviceManagementPage.tableColTTLText').toString(), align: 'left', sortable: true, value: 'ttl' },
    { text: this.$t('deviceManagementPage.tableColIdentText').toString(), align: 'left', sortable: true, value: 'ident' },
    { text: this.$t('deviceManagementPage.tableColCIDText').toString(), align: 'left', sortable: true, value: 'cid' },
    { text: this.$t('deviceManagementPage.tableColLastMessageText').toString(), align: 'left', sortable: true, value: 'lastActive' },
    { text: this.$t('deviceManagementPage.tableColActionText').toString(), value: 'left', align: 'center', sortable: false }
  ]
  public isLoading = false
  public search = ''

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true

      const devices = await FlespiService.fetchDevices()

      this.setDevices(devices)
    } catch {} finally {
      this.isLoading = false
    }
  }

  public deviceSearch(text: string): void {
    this.search = text
  }

  public createDevice(): void {
    this.$refs.deviceManagementDialog.open()
  }

  public editDevice(id: number): void {
    this.$refs.deviceManagementDialog.edit(id)
  }

  public async deleteDevice(id: number) {
    const title = this.$t('deviceManagementPage.deleteDeviceConfirmTitle').toString()
    const text = `
        ${this.$t('deviceManagementPage.deleteDeviceConfirmText1')}<br/>
        ${this.$t('deviceManagementPage.deleteDeviceConfirmText2')}<br/>
        ${this.$t('deviceManagementPage.deleteDeviceConfirmText3')}
    `

    if (await this.confirm({ title, text })) {
      try {
        this.isLoading = true
        await FlespiService.deleteDevice(id)
        this.destroyDevice(id)

        this.setNotification({ text: this.$t('deviceManagementPage.deviceDeletedNotificationText').toString() })
      } catch {} finally {
        this.isLoading = false
      }
    }
  }

  public showDeviceTelemetry(id: number): void {
    this.$router.push({
      path: `device/${id}/telemetry`,
      query: {
        deviceName: this.devices.find(d => d.id === id)!.name
      }
    })
  }

  get tableData(): TableData[] {
    return this.devices.map(d => ({
      id: d.id,
      name: d.name,
      ttl: d.messages_ttl / 86400,
      ident: d.configuration.ident,
      cid: d.cid,
      lastActive: d.last_active
    }))
  }
}

interface TableData {
  id: number
  name: string
  ttl: number
  ident: string
  cid: number
  lastActive: number
}
