import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { CreateDeviceRequest, Device, FlespiService } from '@/App/Services/Flespi.service'
import DeviceTypeSelect from './DeviceTypeSelect/Device-Type-Select.vue'
import { Notification } from '@/store/interfaces/shared.interfaces'

const sharedStore = namespace('Shared')
const deviceManagementStore = namespace('DeviceManagement')
const DEVICE: DeviceForm = {
  name: '',
  ident: '',
  deviceTypeId: null,
  ttl: null,
  phone: null
}

@Component({
  components: { DeviceTypeSelect }
})
export default class DeviceManagementDialog extends Mixins(Validator) {
  $refs: {
    form: HTMLFormElement
  }

  @deviceManagementStore.Mutation
  private readonly addDevice: (device: Device) => void

  @deviceManagementStore.Mutation
  private readonly updateDevice: (device: Device) => void

  @deviceManagementStore.Getter
  public readonly findDeviceById: (id: number) => Device

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isOpen = false
  public isLoading = false
  public form = false
  public id: number | null = null
  public device: DeviceForm = { ...DEVICE }

  public open(): void {
    this.isOpen = true
  }

  public close(): void {
    this.isOpen = false

    this.id = null
    this.device = { ...DEVICE }

    this.$refs.form.resetValidation()
  }

  public edit(id: number): void {
    const device = this.findDeviceById(id)
    this.id = device.id

    this.device = {
      name: device.name,
      ident: device.configuration.ident,
      deviceTypeId: device.device_type_id,
      ttl: device.messages_ttl / 86400,
      phone: device.configuration.phone || null
    }

    this.open()
  }

  public async submit(): Promise<void> {
    if (this.id) {
      await this.update()
    } else {
      await this.store()
    }
  }

  public async store(): Promise<void> {
    try {
      this.isLoading = true

      this.addDevice(await FlespiService.createDevice(this.formData))

      this.setNotification({ text: this.$t('deviceManagementPage.deviceManagementDialog.deviceCreatedNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }

  public async update(): Promise<void> {
    try {
      this.isLoading = true

      this.updateDevice(await FlespiService.updateDevice(this.id!, this.formData))

      this.setNotification({ text: this.$t('deviceManagementPage.deviceManagementDialog.organizationUpdateNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }

  get formData(): CreateDeviceRequest {
    const form: CreateDeviceRequest = {
      name: this.device.name,
      device_type_id: this.device.deviceTypeId!,
      messages_ttl: this.device.ttl! * 86400,
      configuration: {
        ident: this.device.ident
      }
    }

    if (this.device.phone) {
      form.configuration.phone = this.device.phone
    }

    return form
  }

  get title(): string {
    return this.id
      ? this.$t('deviceManagementPage.deviceManagementDialog.dialogTitleCreate').toString()
      : this.$t('deviceManagementPage.deviceManagementDialog.dialogTitleEdit').toString()
  }

  get buttonText(): string {
    return this.id
      ? this.$t('deviceManagementPage.deviceManagementDialog.updateBtnText').toString()
      : this.$t('deviceManagementPage.deviceManagementDialog.saveBtnText').toString()
  }
}

interface DeviceForm {
  name: string
  ident: string
  deviceTypeId: number | null
  ttl: number | null
  phone: string | null
}
