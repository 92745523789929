import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { FlespiService } from '@/App/Services/Flespi.service'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ValidationResult } from '@/types'

const sharedStore = namespace('Shared')

@Component
export default class DeviceTypeSelect extends Mixins(Validator) {
  @Prop({ default: null })
  private readonly value: number | null

  @Prop({ default: false })
  public readonly required!: boolean

  @Prop({ default: false })
  public dark: boolean

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public typeId: number | null = null
  public typesItems: VuetifySelect[] = []
  public isLoading = false

  @Watch('value')
  private valueChanged(val: number): void {
    this.typeId = val
  }

  public validation(): ValidationResult {
    if (!this.required) {
      return true
    }

    return this.validator.isEmpty(this.typeId || '')
  }

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true
      const types = await FlespiService.fetchDeviceTypes()

      this.typesItems = types.map(t => {
        return { value: t.id, text: t.title }
      })

      this.typeId = this.value || null
    } catch {} finally {
      this.isLoading = false
    }
  }
}
